import React from 'react'

import { css } from '@emotion/core'
import { injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import breakpoints from '../utils/breakpoints'
import Coffee from '../components/illustrations/Coffee'

const NotFoundPage = ({ intl }) => {
  return (
    <Layout>
      <SEO
        title="404"
        lang={intl.locale}
        keywords={intl.formatMessage({ id: 'keywords' })}
        description={intl.formatMessage({ id: 'default_description' })}
      />
      <div
        css={theme => css`
          min-height: calc(
            100vh - ${theme.constants.footerHeight}px -
              ${theme.constants.headerHeightMobile}px
          );
          color: ${theme.colors.gray};

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          ${breakpoints.desktop} {
            min-height: calc(
              100vh - ${theme.constants.footerHeight}px -
                ${theme.constants.headerHeightDesktop}px
            );
          }
        `}
      >
        <div
          css={css`
            font-size: 80px;
            opacity: 0.8;
            margin-bottom: 24px;
          `}
        >
          4
          <Coffee
            css={css`
              width: 64px;
              height: 100%;
              margin: 0px 8px 0 16px;
            `}
          />
          4
        </div>
        <div
          css={theme => css`
            font-size: ${theme.fontSize.large};
          `}
        >
          {intl.formatMessage({ id: 'not_found' })}
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(NotFoundPage)
