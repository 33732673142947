import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const Coffee = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['fume01', 'fume02', 'fume03'])}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="59.719"
      viewBox="0 0 42 59.719"
    >
      <g id="blue_coffee" transform="translate(-279 -782.281)">
        <g id="Group_219" data-name="Group 219">
          <g id="coffee">
            <g id="blue_coffee_mug" transform="translate(-209 113)">
              <path
                id="Subtraction_4"
                data-name="Subtraction 4"
                d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
                transform="translate(-13916 6095)"
                fill="#7890e2"
              />
              <path
                id="Rectangle_33"
                data-name="Rectangle 33"
                d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
                transform="translate(488 692)"
                fill="#7890e2"
              />
            </g>
          </g>
          <path
            id="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
        </g>
        <path
          id="fume03"
          d="M-5076.077,781.781V788"
          transform="translate(5376 10.229)"
          fill="none"
          stroke="#dbdbdb"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <path
          id="fume02"
          d="M-5076.077,781.781v14.452"
          transform="translate(5370 2)"
          fill="none"
          stroke="#dbdbdb"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <path
          id="fume01"
          d="M-5076.077,781.781v9.364"
          transform="translate(5364 7.088)"
          fill="none"
          stroke="#dbdbdb"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  )
}

export default Coffee
