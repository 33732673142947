import { css, keyframes } from '@emotion/core'

const firstAnimation = keyframes`
  0% {
    stroke-dashoffset: 10;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 30;
    opacity: 0.2;
  }
`

const secondAnimation = keyframes`
  0% {
    stroke-dashoffset: 15;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 45;
    opacity: 0.2;
  }
`

const thirdAnimation = keyframes`
  0% {
    stroke-dashoffset: 7;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 21;
    opacity: 0.2;
  }
`

export const steamAnimation = (ids, delay = 0) => {
  return css`
    #${ids[0]} {
      stroke-dasharray: 10;
      stroke-dashoffset: 10;
      animation: ${firstAnimation} 1.8s ease-in infinite;
      animation-delay: ${0.1 + delay}s;
    }
    #${ids[1]} {
      stroke-dasharray: 15;
      stroke-dashoffset: 15;
      animation: ${secondAnimation} 1.8s ease-in infinite;
      animation-delay: ${0.6 + delay}s;
    }
    #${ids[2]} {
      stroke-dasharray: 7;
      stroke-dashoffset: 7;
      animation: ${thirdAnimation} 1.8s ease-in infinite;
      animation-delay: ${0.8 + delay}s;
    }
  `
}
